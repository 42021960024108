<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>赛务管理</el-breadcrumb-item>
      <el-breadcrumb-item
        :to="{
          path: '/individual_manage_homeview/individual_race_management',
        }"
        >个人赛赛务工具</el-breadcrumb-item
      >
      <el-breadcrumb-item :to="{
          path: '/individual_racing_tool/examination_room_list',
        }">线下选考场</el-breadcrumb-item>
      <el-breadcrumb-item>选手管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <!-- 考场筛选 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col>
          <span class="title_class">选手筛选</span>
        </el-col>
      </el-row>
      <el-form
        :model="queryForm"
        class="filter_Form"
        label-width="90px"
        ref="queryFormRef"
      >
        <el-row :gutter="50">
          <el-col :span="8">
            <el-form-item label="姓名" prop="userName">
              <el-input
                v-model="queryForm.userName"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系方式" prop="phone">
              <el-input
                v-model="queryForm.phone"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="8">
            <el-form-item label="学校名称" prop="schoolName">
              <el-input
                v-model="queryForm.schoolName"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col> -->
        </el-row>
      </el-form>
      <el-row :gutter="20" type="flex" justify="end">
        <el-col :span="2">
          <el-button
            type="primary"
            style="width: 100%"
            @click="handleToSearch"
            >查询</el-button
          >
        </el-col>
        <el-col :span="2">
          <el-button type="primary" style="width: 100%" @click="handleToReset"
            >重置</el-button
          >
        </el-col>
      </el-row>
    </el-card>
    <!-- 考场管理 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col :span="12">
          <span class="title_class">选手管理</span>
        </el-col>
        <el-col :span="12">
          <div class="div_algin_right">
            <el-button type="primary" plain icon="el-icon-refresh-right" @click="resetAllFn">全部重置</el-button>
            <el-button type="primary" icon="el-icon-plus" @click="stuVisible = true">添加考生</el-button>
            <el-button
                                    class="mr-10"
                                    type="primary"
                                    plain
                                    icon="el-icon-download"
                                    @click="exportExcel"
                                    >考生导出</el-button
                                >
          </div>
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="dataList"
        border
        stripe
      >
        <el-table-column label="序号" type="index" width="50px"></el-table-column>
        <el-table-column label="准考证号" prop="examinationNum">
          <template slot-scope="{ row }">
            {{ row.examinationNum || "-" }}
          </template>
        </el-table-column>
        <el-table-column label="姓名" prop="userName">
          <template slot-scope="{ row }">
            {{ row.userName || "-" }}
          </template>
        </el-table-column>
        <el-table-column label="手机号" prop="phone">
          <template slot-scope="{ row }">
            {{ row.phone || "-" }}
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="120px">
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-refresh-right"
              size="small"
              style="margin-right: 12px;"
              @click="showEditDialog(scope.row)"
              >重置</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.pageNum"
        :page-sizes="[10, 20, 40, 80]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <!-- 添加考生 -->
    <el-dialog title="添加考生"
      :visible.sync="stuVisible"
      width="40%"
      @close="handleCloseDialog">
      <el-form
        :model="addPlayerModel"
        label-width="100px"
        ref="sendProductFormRef"
        :rules="addPlayerRules"
        style="width: 100%"
      >
        <el-form-item label="选手账号" prop="phones">
          <el-input
            type="textarea"
            v-model="addPlayerModel.phones"
            placeholder="手机号用英文逗号分割"
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="stuList"
        border
        stripe
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column label="序号" type="index" width="50px"></el-table-column>
        <el-table-column label="姓名" prop="userName"></el-table-column>
        <el-table-column label="手机号" prop="phone"></el-table-column>
        <el-table-column label="学校" prop="schoolName"></el-table-column>
        <el-table-column label="年级" prop="grade" :formatter="gradeFormat"></el-table-column>
        <el-table-column label="组别" prop="enrollmentLevel" :formatter="levelFormat"></el-table-column>
      </el-table> -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleToAdd">添 加</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { queryExaminationUserListPage, resetAllUser, resetSingleUser, queryUnselectedRoomUserList, batchExaminationRoom } from '@/http/api'
export default {
  data () {
    return {
      dataList: [],
      total: 0,
      queryForm: {
        pageNum: 1,
        pageSize: 10,
        roomId: this.$route.query.id,
        userName: '',
        phone: '',
        schoolName: ''
      },
      addPlayerModel: {
        phones: ''
      },
      addPlayerRules: {
        phones: [{ required: true, message: '请输入选手账号', trigger: 'blur' }]
      },
      dict_group: this.$userInfo.dict_group(),
      dict_grade: this.$userInfo.getGradeData(),
      stuVisible: false,
      stuList: [],
      addQueryForm: {
        raceSchedule: '2', // 复赛
        activityType: this.$chnEngStatusCode.activityType,
        season: this.$route.query.season,
        enrollmentLevel: this.$route.query.rl,
        roomType: this.$route.query.rt
      },
      phones: []
    }
  },
  created () {
    this.getList()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    handleToSearch () {
      this.queryForm.pageNum = 1
      this.getList()
    },
    handleToReset () {
      this.$refs.queryFormRef.resetFields()
      this.getList()
    },
    async getList () {
      queryExaminationUserListPage(this.queryForm).then(res => {
        this.dataList = res.data.list
        this.total = res.data.total
      })
    },
    handleSizeChange (e) {
      this.queryForm.pageSize = e
      this.getList()
    },
    handleCurrentChange (e) {
      this.queryForm.pageNum = e
      this.getList()
    },
    async showEditDialog (row) {
      this.$confirm('是否重置该学生?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        resetSingleUser({ id: row.id }).then(res => {
          this.$message.success('已重置')
          this.handleToSearch()
        })
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消'
        // })
      })
    },
    resetAllFn () {
      this.$confirm('是否重置考场内所有学生?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        resetAllUser({ id: this.queryForm.roomId }).then(res => {
          this.$message.success('已全部重置')
          this.handleToSearch()
        })
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消'
        // })
      })
    },
    exportExcel () {
      var downLoadUrl = this.$env.baseIP + 'examinationUser/excelExport'
      console.log('downLoadUrl', downLoadUrl)
      this.$downLoadFile.downLoadFileWithUrl(this, downLoadUrl, this.queryForm)
    },
    levelFormat (row) {
      const level = row.examinationUserLevel || row.enrollmentLevel
      return level ? this.dict_group.find(item => Number(item.dictValue) === level).dictLabel : '-'
    },
    gradeFormat (row) {
      const grade = row.examinationUserGrade || row.grade
      return grade ? this.dict_grade.find(item => item.dictCode === grade).dictLabel : '-'
    },
    handleToSubmit () {
      this.$refs.dataFormRef.validate(async valid => {
        if (!valid) return false
        delete this.dataForm.dateRange
        const { data: res } = this.editType === 0 ? await this.$http.post('erp/nascc/certificate/create', this.dataForm) : await this.$http.put(`erp/nascc/certificate/edit/${this.editId}`, this.dataForm)
        if (res.code !== 0) {
          this.$message.error(res.message)
        } else {
          this.$message.success('保存成功')
          this.handleCloseDialog()
          this.getList()
        }
      })
    },
    handleToDetails (row) {
      this.$router.push({
        path: '/match_thing_manage/sspcn/certificateDetails',
        query: {
          id: row.id
        }
      })
    },
    daterangeChange (e) {
      if (e.length === 2) {
        this.dataForm.beginTime = e[0]
        this.dataForm.endTime = e[1]
      }
    },
    showStudents () {
      // 调接口
      queryUnselectedRoomUserList(this.addQueryForm).then(res => {
        this.stuList = res.data
      })
    },
    handleCloseDialog () {
      this.stuVisible = false
    },
    handleSelectionChange (arr) {
      // console.log(arr)
      this.phones = arr.map(item => item.phone)
    },
    handleToAdd () {
      batchExaminationRoom({
        roomId: this.queryForm.roomId,
        phones: this.addPlayerModel.phones
      }).then(res => {
        this.$message.success('添加成功')
        this.handleCloseDialog()
        this.handleToSearch()
      })
    }
  }
}
</script>
<style lang="less" scoped>
.div_algin_right{
  text-align: right;
}
</style>
